import React, { useEffect, useState } from 'react';
import { getMenu, getOrders, getTotalOrders } from '../api';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);

  const fetchOrders = async (skip, limit) => {
    setLoading(true);
    try {
      const ordersData = await getOrders(skip, limit);
      setOrders(ordersData);
    } catch (error) {
      setError('Failed to fetch orders.');
    } finally {
      setLoading(false);
    }
  };

  const fetchTotalOrders = async () => {
    try {
      const totalOrders = await getTotalOrders(); // Fetch one order to determine total count
      setTotalOrders(totalOrders);
    } catch (error) {
      setError('Failed to fetch total order count.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const menuData = await getMenu();
        setMenuItems(menuData);
        await fetchOrders(0, 10); // Fetch the last 10 orders initially
        await fetchTotalOrders(); // Fetch total order count
      } catch (error) {
        setError('Failed to fetch data.');
      }
    };
    fetchData();

    const intervalId = setInterval(() => {
      fetchOrders(0, 10); // Always fetch the latest orders and reset to the first page
      setPage(0);
    }, 60000); // Poll every 60 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalOrders / 10);
    setPage((prevPage) => {
      const newPage = Math.min(prevPage + 1, totalPages - 1);
      fetchOrders(newPage * 10, 10);
      return newPage;
    });
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => {
      const newPage = Math.max(0, prevPage - 1);
      fetchOrders(newPage * 10, 10);
      return newPage;
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const getMenuItem = (menuItemId) => {
    return menuItems.find(item => item.id === menuItemId);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Pizza Orders</h1>
      </header>
      <div className="orders-container">
        {orders.map(order => (
          <div key={order.id} className="order-card">
            <h2>Order #{order.id}</h2>
            <p><strong>Customer Name:</strong> {order.customer_name}</p>
            <p><strong>Phone Number:</strong> {order.phone_number}</p>
            <p><strong>Created at:</strong> {order.created_at}</p>
            <p><strong>Total Price:</strong> {order.total_price.toFixed(2)}€</p>
            <h3>Items:</h3>
            <ul>
              {order.items.map(item => (
                <li key={item.id}>
                  {item.quantity} x {item.menu_item_name}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="pagination-buttons">
        <button onClick={handlePreviousPage} disabled={page === 0}>
          Previous
        </button>
        <span>Page {page + 1}</span>
        <button onClick={handleNextPage} disabled={(page + 1) * 10 >= totalOrders}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Orders;
