import React, { useEffect, useState } from 'react';
import { getMenu, addMenuItem, deleteMenuItem, updateMenuItem } from '../api';
import './styles.css'; // Assuming you have some basic styles
import axios from 'axios';

const Menu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newItem, setNewItem] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
    available: true,
    ingredients: ''
  });

  const fetchMenuItems = async () => {
    try {
      const data = await getMenu();
      setMenuItems(data);
    } catch (error) {
      setError('Failed to fetch menu items.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMenuItems();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem({
      ...newItem,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNewItem({
      ...newItem,
      [name]: checked
    });
  };

  const handleAddMenuItem = async (e) => {
    e.preventDefault();
    const ingredients = newItem.ingredients.split(',').map(ingredient => ingredient.trim());
    try {
      await addMenuItem({ ...newItem, ingredients });
      fetchMenuItems();
      setNewItem({
        name: '',
        description: '',
        price: '',
        category: '',
        available: true,
        ingredients: ''
      });
    } catch (error) {
      console.error('Failed to add menu item:', error);
    }
  };

  const handleDeleteMenuItem = async (id) => {
    try {
      await deleteMenuItem(id);
      fetchMenuItems();
    } catch (error) {
      console.error('Failed to delete menu item:', error);
    }
  };

  const handleToggleAvailability = async (id, available) => {
    try {
      await axios.put(`/api/menu_items/${id}`, { available: !available });
      fetchMenuItems();
    } catch (error) {
      console.error('Failed to update availability:', error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="menu">
      <h1>Menu</h1>
      <ul>
        {menuItems.map(item => (
          <li key={item.id}>
            <h2>{item.name}</h2>
            <p>{item.description}</p>
            <p>{item.price}€</p>
            <p>{item.available ? "Available" : "Out of Stock"}</p>
            <ul>
              {item.ingredients.map(ingredient => (
                <li key={ingredient.id}>{ingredient.name}</li>
              ))}
            </ul>
            <button disabled onClick={() => handleDeleteMenuItem(item.id)}>Delete</button>
            <button disabled onClick={() => handleToggleAvailability(item.id, item.available)}>
              {item.available ? "Mark as Out of Stock" : "Mark as Available"}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Menu;
