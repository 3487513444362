import axios from 'axios';

const api = axios.create({
  baseURL: 'https://gourmai.emergo.dev/api',
});

export const getMenu = async () => {
    try {
      const response = await api.get('/menu');
      return response.data;
    } catch (error) {
      console.error('Error fetching menu:', error);
      throw error;
    }
  };

  export const getTotalOrders = async () => {
    try {
      const response = await api.get('/totalOrders');
      return response.data;
    } catch (error) {
      console.error('Error fetching total orders:', error);
      throw error;
    }
  };
  
  export const getOrders = async (skip=0, limit=10) => {
    try {
      const response = await api.get('/orders', {
        params: { skip, limit }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
  };

  export const updateMenuItem = async (id, updates) => {
    const response = await api.put(`/menu_items/${id}`, updates);
    return response.data;
  };

  export const addMenuItem = async (menuItem) => {
    const response = await api.post('/menu_items', menuItem);
    return response.data;
  };
  
  export const deleteMenuItem = async (id) => {
    const response = await api.delete(`/menu_items/${id}`);
    return response.data;
  };